<template>
  <div class="400error">
    <div class="link-div-top">
      <router-link to="./top" class="page_link">Home</router-link> > <router-link to="./ErrorMessage" class="page_link">Error Message</router-link> > 400 SSL Certificate Error
    </div>
    <br /><br /><br />
    <div class="qa">
      <h1>Q. 「400 SSL Certificate Error」が表示されましたがどうすればよいですか？</h1>
      <img alt="400_ssl_certificate_error" src="../../assets/400_ssl_certificate_error.png">
      <h3>Answer</h3>
      <p class="answer">
        このエラー画面はクライアント証明書を所持していない、または間違ったクライアント証明書を選択したエラーになります。<br />
        エラーを解決するためには以下をご確認ください。<br />
        ・ブラウザのキャッシュを削除してください。 マニュアルはこちら <a href="/ClearBrowsingData.pdf" target="_blank" rel="noopener noreferrer">ClearBrowsingData.pdf</a><br />
        ・ブラウザを全て閉じて再度アクセスしてください。<br />
        ・正しいクライアント証明書を選択してください。<br />
        もし上記を試してもエラーが表示される場合は、ユーザ管理者様かスズキ証明書窓口(<a href="mailto:certmgr@hhq.suzuki.co.jp">certmgr@hhq.suzuki.co.jp</a>)までご連絡いただき、証明書を再発行してください。<br />
      </p>
    </div>
    <br /><br />
    <div class="link-div-bottom">
      <router-link to="./ErrorMessage"><img id="back" src="/arrow_back.png" v-on:mouseover="imageBackChange" v-on:mouseleave="imageBackReturn" /></router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link to="./403error"><img id="forward" src="/arrow_forward.png" v-on:mouseover="imageForwardChange" v-on:mouseleave="imageForwardReturn" /></router-link>
    </div>
  </div>
</template>
<style>
.qa {
  min-width:800px;
  width:auto !important;
  width:800px;
  border-style:solid;
  border-width:1px;
  border-color:gray;
  border-radius:3px;
  padding:20px;
}
.answer {
  font-size:20px;
}

.link-div-top {
  text-align:left;
}

.link-div-bottom {
  text-align:center;
}

#forward {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

#back {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

</style>
<script>
export default {
  methods: {
    imageForwardChange: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward_on.png'
    },
    imageForwardReturn: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward.png'
    },
    imageBackChange: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back_on.png'
    },
    imageBackReturn: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back.png'
    }
  }
}
</script>
